
import React from 'react';
import Layout from "components/layout";
import SEO from 'components/seo';
import { Link, } from "gatsby";
import { get, } from 'lodash';
import { PostListItem, } from './post-list';

const CategoryListPage = (props) => {
  // console.log('category-list-page');
  const { category } = props.pageContext;
  // console.log(category);
  const imgUrl = get(category, 'featured_image.path', null);
  return (
    <React.Fragment>
      <SEO
        title={`posts about ${category.name}`}
      />
      <h1>Posts about {category.name}</h1>
      {imgUrl && <img src={imgUrl} alt={category.name} />}
      <h2>{category.description}</h2>
      <hr />

      {category.posts.map((post) => <PostListItem key={`pli-${post._id}`} post={post} />)}

      {/*<ul>*/}
      {/*  {category.posts.map((post) =>*/}
      {/*    <li key={`post-item-link-${post._id}`}><Link to={post.meta.postUrl}>{post.title}</Link></li>)}*/}
      {/*</ul>*/}

    </React.Fragment>
  );
};

export default CategoryListPage;
