
import React from 'react';
import cx from 'classnames';
import SEO from 'components/seo';
import { Link, } from 'components/link';
import LazyLoad from 'react-lazyload';
import { get, } from 'lodash';
import fecha from 'fecha';
import { getImageFromImageMap, } from './post';
import styles from './post.module.css';

const ReadMoreLink = ({ excerpt, link, }) => {
  if (!excerpt) {
    return (<noscript />);
  }
  return (
    <>
      &nbsp;
      <Link to={link}>more &gt;&gt;</Link>
    </>
  );
};

const PostExcerpt = ({ post, postUrl, }) => {
  const isQuote = (post.tags || []).indexOf('quote') !== -1;
  if (isQuote) {
    return (
      <blockquote
        dangerouslySetInnerHTML={{ __html: post.body, }}
      />
    );
  }
  return (
    <p className={styles.postSummary}>
      {post.summary}
      <ReadMoreLink excerpt={post.summary} link={postUrl} />
    </p>
  );
};

const HeaderImage = ({ postUrl, src, alt, }) => {
  if (!src) {
    return (<noscript />);
  }
  return (
    <Link className={styles.imageLink} to={postUrl}>
      <LazyLoad height={300}>
        <img src={src} alt={alt} />
      </LazyLoad>
    </Link>
  );
};

const PostListItem = (props) => {
  const { post, } = props;
  const imageUrl = getImageFromImageMap(post, get(post, 'featured_image.path', null));
  const postUrl = `/post/${post.slug}`;
  return (
    <div className={styles.postListItem}>
      <HeaderImage src={imageUrl} alt={`${post.title}`} postUrl={postUrl} />
      <h3 className={cx(styles.postTitle)}><Link to={postUrl}>{post.title}</Link></h3>
      <p className={styles.datetime}>
        {fecha.format(new Date(`${post.published_at} ${post.published_at_time}`), 'MMMM D, YYYY, h:mm A')}
      </p>
      <PostExcerpt
        post={post}
        postUrl={postUrl}
      />
      <hr className={styles.postHr} />
    </div>
  );
};

const PostList = (props) => {
  const titlesAndSuch = [
    'how now, brown cow',
    'spaghetti pockets',
    'cheese',
    'relax, i\'ve got no underpants on.',
    'how now brown cow',
    'spaghetti pockets',
    '安心してください。はいてませんよ',
  ];
  // console.log('PostList');
  // console.log(props.pageContext);
  const {
    postList,
    nextPath,
    previousPath
  } = props.pageContext;
  return (
    <React.Fragment>
      <SEO
        title={titlesAndSuch[Math.floor(Math.random() * titlesAndSuch.length)]}
      />
      {postList.map((post) => (<PostListItem key={`post-list-item-${post.slug}`} post={post} />))}
      <ul>
        {previousPath && <li><Link to={previousPath}>previous</Link></li>}
        {nextPath && <li><Link to={nextPath}>next</Link></li>}
      </ul>
    </React.Fragment>
  );
};

export {
  PostList,
  PostListItem,
};
export default PostList;
