
import React from 'react';
import { Link } from 'gatsby';

const LinkWithPrevUrl = ({ children, state, ...rest }) => {
  const newState = Object.assign({}, state, {
    previousUrl: typeof window !== 'undefined' ? window.location.pathname: null,
  });
  return (<Link {...rest} state={newState}>{children}</Link>);
};

export { LinkWithPrevUrl as Link }
